<template>
  <div class="product">
    <div class="zhdj">
      <div class="zhdj_box">
        <p>智慧党建</p>
        <p>架起党员与群众的连心桥</p>
        <div class="btn">在线咨询</div>
      </div>
    </div>
    <div class="hxld">
      <p>核心亮点</p>
      <div class="hxld_box">
        <img class="img" id="img" src="@/assets/images/product/zhdj/hxld.png" />
        <div class="hxld_box_item" id="title">
          <div class="title">
            <img
              class="icon"
              src="@/assets/images/product/zhdj/hxld_icon1.png"
            />
            <p class="first_line">党员报到</p>
            <p class="second_line">
              党员线上报到<br />实现党员信息线上统一管理
            </p>
          </div>
          <div class="title">
            <img
              class="icon"
              src="@/assets/images/product/zhdj/hxld_icon2.png"
            />
            <p class="first_line">党员实事</p>
            <p class="second_line">群众随时查看党员实事<br />了解党建要闻</p>
          </div>
        </div>
      </div>
    </div>
    <div class="dyxx">
      <div class="dyxx_box">
        <div class="dyxx_box_item" id="title2">
          <div class="title">
            <img
              class="icon"
              src="@/assets/images/product/zhdj/dyxx_icon1.png"
            />
            <p class="first_line">党员学习</p>
            <p class="second_line">线上分享发布党员学习情况</p>
          </div>
          <div class="title">
            <img
              class="icon"
              src="@/assets/images/product/zhdj/dyxx_icon2.png"
            />
            <p class="first_line">党建活动</p>
            <p class="second_line">党员线上报名参加党建活动</p>
          </div>
        </div>
        <img
          class="img"
          id="img2"
          src="@/assets/images/product/zhdj/dyxx.png"
        />
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  data () {
    return {

    }
  },
  mounted () {
    $(window).scroll(this.scrollBar)
  },
  methods: {
    scrollBar () {
      const h = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
      var dyxxH = $('.dyxx').offset().top
      if (h > dyxxH - 500) {
        $('#img2').addClass('animation')
      }
    }
  },
  destroyed () {
    $(window).unbind('scroll', this.scrollBar)
  }
}
</script>

<style scoped lang="scss">
@import './style/index.scss';
</style>
